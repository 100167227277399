.fc-highlight {
	opacity: 0.5;
	background-color: lightblue !important;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: white !important;
}

.fc-timegrid-slot {
	background-color: white !important;
}

.fc-highlight {
	background: yellow !important;
}

.fc-v-event {
	background-color: var(--fc-event-bg-color);
	border: 1px solid var(--fc-event-border-color);
	display: block;
}

.fc .fc-daygrid-body .fc-daygrid-row {
	min-height: auto; /* Allow rows to expand */
	height: auto !important; /* Override fixed height */
}

/* Ensure that the individual date cells can also expand as needed */
.fc .fc-daygrid-body .fc-daygrid-day {
	height: auto !important;
}

/* Adjust the event's styles if necessary */
.fc .fc-daygrid-event {
	white-space: normal; /* Allow event titles to wrap */
}
