@import './assets/sass/style.scss';

@import './assets/sass/plugins.scss';

@import './assets/sass/full-calendar.scss';

@import './assets/sass/style.angular.scss';

@import './assets/plugins/keenicons/duotone/style.css';
@import './assets/plugins/keenicons/outline/style.css';
@import './assets/plugins/keenicons/solid/style.css';

@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

.modal-header {
	justify-content: space-between;
}

@for $i from 1 through 4 {
	.text-trancate-#{$i} {
		display: -webkit-box !important;
		-webkit-line-clamp: $i !important;
		-webkit-box-orient: vertical !important;
		overflow: hidden !important;
	}
}

@for $i from 1 through 8 {
	.flex-#{$i} {
		flex: $i !important;
	}
}

@for $i from 1 through 40 {
	.max-h-#{$i * 25}px {
		max-height: $i * 25px;
	}
}

@for $i from 1 through 40 {
	.max-w-#{$i * 25}px {
		max-width: $i * 25px;
	}
}

.page-loading {
	min-height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card.overlay {
	> .card-body {
		min-height: 250px;
	}
}

.overlay .overlay-layer {
	background-color: rgba(255, 255, 255, 0.8) !important;
	z-index: 10;
}

.card .card-header.card-advanced-search {
	min-height: 40px !important;
	align-items: center !important;
}

.custom-footer {
	position: sticky;
	bottom: 0;
	height: 67px;
	background-color: white;
	z-index: 10;
	box-shadow: 0px -1px 0px rgba(50, 50, 50, 0.15);
}

.ng-select.custom {
	min-height: 42px;
	width: 100%;
	display: flex;
	align-items: center;
}

.ng-select.custom.is-invalid .ng-select-container {
	border: 1px solid var(--bs-danger);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1416c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1416c' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(2em + 0.3875rem) center;
	background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}

.ng-select.custom .ng-select-container {
	min-height: 42px !important;
	border-color: var(--bs-gray-300);
}

.ng-select.custom.ng-select-single ::ng-deep .ng-select-container {
	min-height: 42px;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-input > input {
	padding-top: 6px !important;
}

.input-group-text.is-invalid {
	border-color: var(--bs-danger) !important;
}

button:disabled,
input:disabled {
	cursor: not-allowed !important;
	opacity: 0.65 !important;
}

.pointer-none {
	pointer-events: none;
	opacity: 0.4;
	cursor: default;
}

.separator {
	border-bottom: 1px solid #e5e5e5 !important;
}

.border-custom {
	border: 1px solid #e5e5e5 !important;
	border-radius: 8px;
}

.border-right {
	border-right: 1px solid #e5e5e5 !important;
}

.border-none {
	border: none !important;
}

.border-right-none {
	border-right: none !important;
}

.word-break-all {
	word-break: break-all;
}

.custom-separator {
	display: block;
	height: 0;
	border-bottom: 1px solid #e5e5e5 !important;
}

.star {
	color: grey;
	font-size: 2rem;
}
.star.filled {
	color: gold;
}

.star.half-filled {
	/* Example using linear-gradient for half-filled star */
	background: linear-gradient(to right, gold 50%, grey 50%);
	-webkit-background-clip: text;
	color: transparent;
}

.btn-xs {
	padding: 0.25rem 0.7rem !important;
}

.modal-2xl {
	max-width: 1500px !important;
}

.white-space-wrap {
	white-space: pre-wrap;
}

.pdf-border {
	border: 1px dashed;
	border-radius: 8px;
	align-items: center;
	display: flex;
	justify-content: center;
	cursor: pointer;
	padding: 2rem;
}

.flex-evenly {
	justify-content: space-evenly;
}

.drag-handle {
	border: 1px solid #ddd;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	// Important because the material design would overwrite it in the mat-list
	width: 28px !important;
	height: 28px !important;
}

.layout-padding {
	padding: 8px;
}

.layout-padding > * {
	margin: 8px;
}

.scrollable {
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: auto;
}

[dndHandle],
[draggable='true']:not(:has([dndHandle])) {
	cursor: pointer;
}

[draggable].dndDraggableDisabled {
	cursor: not-allowed;
	opacity: 0.7;
}

[dndDropzone] {
	// border: 1px red solid;
	min-width: 200px;
	min-height: 200px;
}

[dndDropzone].dndDropzoneDisabled {
	cursor: no-drop;
	opacity: 0.7;
	border-color: gray;
}

[dndDropzone].dndDragover {
	border-color: green;
}
